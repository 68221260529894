import { HttpParams } from '@angular/common/http';
import { Component } from '@angular/core';
import { ColDef, GridOptions, GridReadyEvent, IRichCellEditorParams } from 'ag-grid-community';
import { ConfirmationService } from 'primeng/api';
import { Crop } from 'src/app/models/crop';
import { PreCodePreferences } from 'src/app/models/preCodePreferences';
import { ApiService } from 'src/app/services/api.service';
import { CustomMessageService } from 'src/app/services/custom.message.service';
import { StaticFields } from 'src/app/shared/staticFields';
import { GeassessmentmodalComponent } from '../geassessmentmodal/geassessmentmodal.component';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { UserPreferredGeTrait } from 'src/app/models/userPreferredGeTrait';
import { PStageInfo } from 'src/app/models/pStageInfo';
import { CheckboxChangeEvent } from 'primeng/checkbox';
import { CropUpdateService } from 'src/app/services/cropUpdate.service';

@Component({
  selector: 'app-preferences',
  templateUrl: './preferences.component.html',
  styleUrl: './preferences.component.scss'
})
export class PreferencesComponent {
  stageDict: any = {};
  selectedCrop: any;
  levels: any[] = [{name: 'Program(s)'},{name: 'Region(s)'},{name: 'EZ(s)'}];
  selectedLevel: any;
  inclusions: any[];
  selectedInclusions: any[] = [];
  stages: string[] = [];
  selectedStages: any[] = [];
  years: string[] = StaticFields.years;
  selectedYears: any[] = [];
  geLineageChecked: boolean = false;
  seedInventoryChecked: boolean = false;
  popRequestChecked: boolean = false;
  experimentsChecked: boolean = false;
  regions: any[] = [];
  ezs: any = [];
  programs: any = [];
  programsCopy: any = [];
  geAssessmentsList: UserPreferredGeTrait[] = [];
  selectedAssessments: any[] = [];
  precodePreference : PreCodePreferences = new PreCodePreferences();
  settingPageLoadData: boolean = false;
  ref: DynamicDialogRef | undefined;
  gePreference: any[] = [];
  pStageChecked: boolean = false;
  genToFetch: any = {name:"0", val: 0};
  genList: any = [{name:"All", val: -100},{name:"5", val: 5},{name:"4", val: 4},{name:"3", val: 3},{name:"2", val: 2},{name:"1", val: 1},{name:"0", val: 0},{name:"-1", val: -1},{name:"-2", val: -2},{name:"-3", val: -3},{name:"-4", val: -4},{name:"-5", val: -5}];
  selectedPStages: any[] = [];
  pStagesList: any[] = [];
  showPStageModal: boolean = false;
  pStageData: any[] = [];
  pStageColDef: ColDef[] = [
    { field: 'crop', headerName: 'Crop',cellEditor: 'agSelectCellEditor', editable: true, onCellValueChanged(event) {
      if (event.data.crop !== event.context.componentParent.selectedCrop.Name) {
        event.context.componentParent.changeCrop(true,event.data.crop);
      }
    },
      cellEditorParams: () => { return { values: this.crops.map((ele:any) => ele.Name), valueListMaxHeight: 250 } } },
    { field: 'type', headerName: 'Type', cellEditor: 'agSelectCellEditor', editable: true,
      cellEditorParams: () => { return { values: ["Program(s)","Region(s)","EZ(s)"], valueListMaxHeight: 100 } }},
    { field: 'name', headerName: 'Name', cellEditor: 'agRichSelectCellEditor' , editable: true,
      cellEditorSelector: params => {
        let type : any[] = [];
        if (params.data.crop === this.selectedCrop.Name)
           type = params.data.type === 'Program(s)' ? this.programs : params.data.type === 'Region(s)' ? this.regions : this.ezs;   
        else
           type = params.data.type === 'Program(s)' ? this.pStagePrograms : params.data.type === 'Region(s)' ? this.pStageRegions : this.pStageEzs;        
        return {
          component: 'agRichSelectCellEditor',
          params: {
            values: type.map((d: any) => d.Code),
            searchType: 'matchAny',
            filterList: true,
            highlightMatch: true,
            multiSelect: true
          }
        }
      }
    },
    { field: 'year',headerName : 'Year(s)',  cellEditor: 'agRichSelectCellEditor', editable: true,
      cellEditorParams: {
        values: StaticFields.years,
        multiSelect: true,
        searchType: 'matchAny',
        filterList: true,
        highlightMatch: true
      } as IRichCellEditorParams    
    }
  ];
  defaultPStageColDef: ColDef = {
    sortable: true,
    filter: true,
    minWidth : 170, 
    resizable: true,
  } ;
  gridOptionsPStage : GridOptions = {
    context: {
      componentParent: this,
    },
    singleClickEdit: true
  }
  pStageDataCopy: any;
  pStageGridApi: any;
  selectedPStageCrop: any;
  pStageRegions: any;
  pStageEzs: any;
  pStagePrograms: any;
  pStageCounter: number = 0;
  favouritePrograms: any[] = [];
  showSelFavorites: boolean = false;
  cropReset: boolean;

  constructor(public customMessageService: CustomMessageService, private apiService : ApiService, private confirmationService: ConfirmationService, public dialogService: DialogService, private cropUpdateService : CropUpdateService) { 
    this.cropUpdateService.resetData$.subscribe(res => {
      if (res) {
        this.cropReset = true;
        this.resetPreferences();
      }
    });
  }

  get allowReset() : boolean {
    if(StaticFields.precodePreference?.appUserId && this.selectedCrop && this.selectedLevel){
      const preSelectedAssessments = StaticFields.precodePreference.userPreferredGeTraits.map(({ traitId, traitName,traitDescription,aois,years,selected }) => ({ traitId, traitName,traitDescription,aois,years,selected }));
      const selectedAssessments = this.geAssessmentsList.map(({ traitId, traitName,traitDescription,aois,years,selected }) => ({ traitId, traitName,traitDescription,aois,years,selected }));
      const preSelectedPStages = StaticFields.precodePreference.userPreferredPStages.map(({ id, crop,type,name,year,selected }) => ({ id, crop,type,name,year,selected }));
      const selectedPStages= this.pStagesList.map(({ id, crop,type,name,year,selected }) => ({ id, crop,type,name,year,selected }));
      
      return this.selectedCrop.Name !== StaticFields.precodePreference.crop || this.selectedLevel.name !== StaticFields.precodePreference.level || 
      ((StaticFields.precodePreference.include !== "" || this.selectedInclusions.join(",") !== "") && JSON.stringify(this.selectedInclusions.map(i => i.code).sort((a,b) => a-b)) !== JSON.stringify(StaticFields.precodePreference.include.split(",").sort((a: any,b: any) => a-b))) ||
      ((StaticFields.precodePreference.stage !== "" || this.selectedStages.join(",") !== "") && JSON.stringify(this.selectedStages.sort((a,b) => a-b)) !== JSON.stringify(StaticFields.precodePreference.stage.split(",").sort((a: any,b: any) => a-b))) ||
      ((StaticFields.precodePreference.years !== "" || this.selectedYears.join(",") !== "") && JSON.stringify(this.selectedYears.sort((a,b) => a-b)) !== JSON.stringify(StaticFields.precodePreference.years.split(",").sort((a: any,b: any) => a-b))) ||
      ((StaticFields.precodePreference.favouritePrograms !== "" || this.favouritePrograms.join(",") !== "") && JSON.stringify(this.favouritePrograms.map(p => p.Code).sort((a,b) => a-b)) !== JSON.stringify(StaticFields.precodePreference.favouritePrograms.split(",").sort((a: any,b: any) => a-b))) ||
      this.geLineageChecked !== StaticFields.precodePreference.geLineage || this.popRequestChecked !== StaticFields.precodePreference.popRequest || StaticFields.precodePreference.gensToSummarize !== this.genToFetch ||
      this.seedInventoryChecked !== StaticFields.precodePreference.seedInventory || this.experimentsChecked !== StaticFields.precodePreference.experiments ||  
      ((StaticFields.precodePreference.userPreferredGeTraits.length > 0 || this.geAssessmentsList.length > 0 ) && JSON.stringify(preSelectedAssessments) !== JSON.stringify(selectedAssessments)) ||
      ((StaticFields.precodePreference.userPreferredPStages.length > 0 || this.pStageData.length > 0 ) && JSON.stringify(preSelectedPStages) !== JSON.stringify(selectedPStages));
    } else {
      return this.selectedCrop || this.selectedLevel || this.selectedInclusions?.length > 0
    }
  }

  get crops(): Crop [] {
    return StaticFields?.crops && StaticFields?.crops?.length > 0 ? StaticFields?.crops?.filter(ele => ele.isActive) : [];
  }

  get validPstageData() : number {
    let count = 0;
    this.pStageData.forEach(ele => {
      if(ele.type !== "" && (ele.name.length > 0 && ele.year.length > 0)) {
        count++;
      }
    })
    return count;
  }

  get allowPStageSettingSave() : boolean {
    return JSON.stringify(this.pStageData) === JSON.stringify(this.pStageDataCopy);
  }

  ngOnInit(): void {
    if (StaticFields.appUserId)
      this.setScreenData();  
    else {
      const interval = setInterval(() => {
        if (StaticFields.appUserId) {
          this.setScreenData();
          clearInterval(interval);
        }
      }, 2000);
    }
  }

  setScreenData() {
    if (StaticFields.precodePreference && StaticFields.precodePreference.appUserId) {
      this.selectedCrop = this.crops.find(ele => ele.Name == StaticFields.precodePreference.crop);
      if(this.selectedCrop) {
      this.changeCrop(false,this.selectedCrop.Name);        
      this.selectedLevel = this.levels.find(ele => ele.name == StaticFields.precodePreference.level);
      this.changeLevel();
      this.selectedYears = StaticFields.precodePreference.years !== "" && StaticFields.precodePreference.years.split(",").length > 0 ? StaticFields.precodePreference.years.split(",") : [] ;
      this.geLineageChecked = StaticFields.precodePreference.geLineage;
      this.popRequestChecked = StaticFields.precodePreference.popRequest;
      this.seedInventoryChecked = StaticFields.precodePreference.seedInventory;
      this.experimentsChecked = StaticFields.precodePreference.experiments;
      this.genToFetch = StaticFields.precodePreference.gensToSummarize;     
      this.geAssessmentsList = JSON.parse(JSON.stringify(StaticFields.precodePreference.userPreferredGeTraits));
      this.selectedAssessments = this.geAssessmentsList.filter(ele => ele.selected == true);
      this.pStagesList = JSON.parse(JSON.stringify(StaticFields.precodePreference?.userPreferredPStages ? StaticFields.precodePreference.userPreferredPStages : [] )); 
      this.pStageData =  JSON.parse(JSON.stringify(StaticFields.precodePreference?.userPreferredPStages ? StaticFields.precodePreference.userPreferredPStages : [] ));   
      this.pStageData.forEach(ele => {ele.name = ele.name.split(",");ele.year = ele.year.split(",")});
      this.selectedPStages = this.pStagesList.filter(ele => ele.selected == true);
      } else {
        this.customMessageService.showMessage({severity: 'warn', summary:'Crop saved as preference was inactive' , detail: 'Crop have to be active to load default preferences'});
      }
      
    }
  }

  changeCrop(isPStage? : boolean, crop? : string) {
    if(StaticFields.precodePreference && StaticFields.precodePreference?.crop && StaticFields.precodePreference?.crop !== this.selectedCrop.Name && !isPStage) {
      this.confirmationService.confirm({
        header: 'Confirmation',
        icon: 'pi pi-exclamatison-triangle',
        message: `Changes made will be lost if the crop was changed without saving?`,     
        accept: () => {
          this.geAssessmentsList= [];
          this.pStageData = [];
          this.genToFetch = {name:"0", val: 0};
          this.selectedAssessments = [];
          this.selectedPStages = [];
          this.getMasterData();
        },
        reject: () => {
          this.selectedCrop = this.crops.find(ele => ele.Name == StaticFields.precodePreference.crop);
        }
      });
    } else {
        this.getMasterData(isPStage,crop);
    }
  }

  changeLevel() {
    if (this.selectedLevel.name === "Program(s)") {
      this.inclusions = this.programs.map((d: any) => { return {code: d.Code, desc: d.Description};});
    } else if (this.selectedLevel.name === "Region(s)") {
      this.inclusions = this.regions.map((d: any) => { return {code: d.Code, desc: d.Description};});
    } else if (this.selectedLevel.name === "EZ(s)") {
      this.inclusions = this.ezs.map((d: any) => { return {code: d.Code, desc: d.Description};});
    } else if (this.inclusions){
      this.inclusions.splice(0);
    }
    if (StaticFields.precodePreference && StaticFields.precodePreference.appUserId && this.inclusions.length > 0) {
      const inclPredicate = StaticFields.precodePreference.include.split(",").some(ele => this.inclusions.findIndex((i: any) => i.code === ele) > -1);
      const stagePredicate = StaticFields.precodePreference.stage.split(",").some(ele => this.stages.includes(ele));
      const selInclusions = inclPredicate && StaticFields.precodePreference.include!= "" &&  StaticFields.precodePreference.include.split(",").length > 0 ?  StaticFields.precodePreference.include.split(",").filter(ele => this.inclusions.findIndex((i: any) => i.code === ele) > -1) : [];
      this.selectedInclusions = this.inclusions.filter(i => selInclusions.includes(i.code));
      this.selectedStages = stagePredicate && StaticFields.precodePreference.stage != "" && StaticFields.precodePreference.stage.split(",").length > 0 ? StaticFields.precodePreference.stage.split(",").filter(ele => this.stages.indexOf(ele) > -1) : [];
      this.favouritePrograms = [...StaticFields.precodePreference.favouritePrograms.split(",")];
      this.favouritePrograms = this.programs.filter((ele : any) => this.favouritePrograms.indexOf(ele.Code) !== -1);
    }
  }

  getRegionsPrograms(isPStage ?:  boolean, crop? : string) {
    let params = new HttpParams();
    const cropId = isPStage ? this.crops.find(ele => ele.Name === crop).Id : this.selectedCrop.id;
    params = params.append('cropId', cropId);
    if(StaticFields.precodePreference && StaticFields.precodePreference?.crop === this.selectedCrop.Name)
      this.geAssessmentsList = [...StaticFields.precodePreference.userPreferredGeTraits];
    this.apiService.getRADData(`Regions?$filter=CropId eq ${this.selectedCrop.Id} and Status/Name eq 'Active'&$select=Id,Code,Description`).subscribe((result : any) => {
      result.value = result.value.sort((a: any , b: any) => a.Code.localeCompare(b.Code));   
      if(!isPStage) {
        this.regions = result.value;
        if (this.selectedLevel && this.selectedLevel.name === "Region(s)")
          this.changeLevel();
        } else {
          this.pStageRegions = result.value;
        }     
    });
    this.apiService.getRADData(`EvaluationZones?$filter=CropId eq ${this.selectedCrop.Id} and Status/Name eq 'Active'&$select=Id,Code,Description`).subscribe((result : any) => {
      result.value = result.value.sort((a: any , b: any) => a.Code.localeCompare(b.Code));  
      if(!isPStage) {
          this.ezs = result.value;
          if (this.selectedLevel && this.selectedLevel.name === "EZ(s)")
            this.changeLevel();
        }
        else {
          this.pStageEzs = result.value;
        } 
    });
    this.apiService.getRADData(`Programs?$filter=CropId eq ${this.selectedCrop.Id} and Status/Name eq 'Active'&$select=Id,Code,Description`).subscribe((result : any) => {
        result.value = result.value.sort((a: any , b: any) => a.Code.localeCompare(b.Code));  
        if(!isPStage) {
          this.programs = result.value;
          this.programsCopy = JSON.parse(JSON.stringify(this.programs));
          if (this.selectedLevel && this.selectedLevel.name === "Program(s)")
            this.changeLevel();
        }
        else {
          this.pStagePrograms = result.value;
        }   
    });
  }

  getMasterData(isPStage? : boolean, crop? : string) {
    if(!isPStage) {
      this.stageDict = {};
      this.stages.splice(0);
      StaticFields.masterData?.filter(p => p.type == 'Stage' && p.crop ==this.selectedCrop.Name && p.isActive).forEach((s: any) => {   
        if (s.stage && !(s.stage in this.stageDict)){      
          this.stageDict[s.stage.toUpperCase()] = [];
          this.stageDict[s.stage.toUpperCase()].push(s.stage);
          this.stages.push(s.stage);
        }
        // if (s.nextStage) {
        //     this.stageDict[s.stage.toUpperCase()].push(s.nextStage);
        //     const foundNextStage = StaticFields.masterData?.find((d: any) => d.stage.toUpperCase() === s.nextStage.toUpperCase())
        //     if (foundNextStage && foundNextStage.nextStage)
        //         this.stageDict[s.stage.toUpperCase()].push(foundNextStage.nextStage);
        // }
          
      }); 
    }
    this.getRegionsPrograms(isPStage,crop);      
  }
  
  getExpandOptions() {
    const expandBy: string[] = [];
    if (this.geLineageChecked)
      expandBy.push('GE');
    if (this.seedInventoryChecked)
      expandBy.push('SI');
    if (this.popRequestChecked)
      expandBy.push('POP');
    if (this.experimentsChecked)
      expandBy.push('EXPT');
    return expandBy.join();
  }

  onRowClick(event : any) {
    event["selected"] = !event["selected"];
  }

  changeFavoritesView($event: CheckboxChangeEvent) {
    this.programsCopy = $event.checked ? JSON.parse(JSON.stringify(this.favouritePrograms)) : JSON.parse(JSON.stringify(this.programs));
  }

  resetPreferences() {
    if(!this.cropReset) {
      this.confirmationService.confirm({
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        message: `Are you sure you want to reset the screen?`,     
        accept: () => {
          if (StaticFields.precodePreference && StaticFields.precodePreference.appUserId ) {
            this.setScreenData();
          } else {
            this.selectedCrop = this.selectedLevel = this.selectedInclusions = this.selectedStages = this.selectedYears = null;
            this.favouritePrograms = [];
            this.programsCopy = [];
            this.genToFetch = {name:"0", val: 0};
            this.geLineageChecked = this.popRequestChecked = this.experimentsChecked = this.seedInventoryChecked = false;
          }
        }
      });
    } else {
      this.selectedCrop = this.selectedLevel = this.selectedInclusions = this.selectedStages = this.selectedYears = null;
      this.favouritePrograms = this.programsCopy = [];
      this.genToFetch = {name:"0", val: 0};
      this.geLineageChecked = this.popRequestChecked = this.experimentsChecked = this.seedInventoryChecked = false;
      this.cropReset = false;
      StaticFields.precodePreference.crop = "";
      StaticFields.precodePreference.level = "";
      StaticFields.precodePreference.include = "";
      StaticFields.precodePreference.stage =   '';
      StaticFields.precodePreference.favouritePrograms =  '';
      StaticFields.precodePreference.years = '';
      StaticFields.precodePreference.geLineage = false;
      StaticFields.precodePreference.popRequest = false;
      StaticFields.precodePreference.seedInventory = false;
      StaticFields.precodePreference.experiments = false;
      StaticFields.precodePreference.gensToSummarize = {name:"0", val: 0};;
      StaticFields.precodePreference.userPreferredGeTraits = [];   
      StaticFields.precodePreference.userPreferredPStages = [];
      this.savePreferences(true)
    }
    
  }

  savePreferences(resetCrop? : boolean) {
    if (!StaticFields.precodePreference)
      StaticFields.precodePreference = new PreCodePreferences();
    if (!StaticFields.precodePreference.appUserId) {
      StaticFields.precodePreference.appUserId = StaticFields.appUserId;
    }
    if(!resetCrop) {
      StaticFields.precodePreference.type = 'PreCode';
      StaticFields.precodePreference.crop = this.selectedCrop.Name;
      StaticFields.precodePreference.level = this.selectedLevel.name;
      StaticFields.precodePreference.include = this.selectedInclusions.map(i => i.code).join();
      StaticFields.precodePreference.stage =  this.selectedStages ? this.selectedStages.join() : '';
      StaticFields.precodePreference.favouritePrograms =  this.favouritePrograms ? this.favouritePrograms.map(ele => ele.Code).join() : '';
      StaticFields.precodePreference.years =  this.selectedYears ? this.selectedYears.join() : '';
      StaticFields.precodePreference.geLineage = this.geLineageChecked;
      StaticFields.precodePreference.popRequest = this.popRequestChecked;
      StaticFields.precodePreference.seedInventory = this.seedInventoryChecked;
      StaticFields.precodePreference.experiments = this.experimentsChecked;
      StaticFields.precodePreference.gensToSummarize = this.genToFetch;
      const cropId = this.crops.find(ele => ele.Name === this.selectedCrop.Name).Id;
      this.geAssessmentsList.forEach(ele => ele.cropId = cropId);
      StaticFields.precodePreference.userPreferredGeTraits = this.geAssessmentsList;   
      this.pStagesList.forEach(ele => delete ele.id);
      StaticFields.precodePreference.userPreferredPStages = this.pStagesList;
    }
    const pref = JSON.parse(JSON.stringify(StaticFields.precodePreference));
    pref.gensToSummarize = pref.gensToSummarize.val;
    this.apiService.putData('SavePreferences', pref).subscribe(res => {
      if(res.httpStatusCode == 200) {
        let params = new HttpParams();
        params = params.append('type', 'PreCode');
        params = params.append('appUserId', StaticFields.appUserId);
        this.apiService.getData('GetPreferences', params, false).subscribe((res: any) => {
          if (res.httpStatusCode == 200) {
            StaticFields.precodePreference = res.data[0];
            if (res.data) {
              const name = StaticFields.precodePreference.gensToSummarize === -100 ? "All" : StaticFields.precodePreference.gensToSummarize.toString();
              StaticFields.precodePreference.gensToSummarize = {name: name, val: StaticFields.precodePreference.gensToSummarize};
            }
          }
          this.setScreenData();
        });
      }
    });     
  }

  addGeAssessments() {
    this.ref = this.dialogService.open(GeassessmentmodalComponent, { 
        data : {crops : this.crops, selectedCrop :this.selectedCrop, selectedAssessmentsList :this.geAssessmentsList },
        header: 'Search Germplasm Assessments',
        width: '95%',
        height: '90vh',
        styleClass: 'geModal',
        appendTo: 'body',
        closable : false
    });

    this.ref.onClose.subscribe((result : any) => {
      if (result) {
          const newAssessmentMembers = result.assessmentMembers.map((member : any) => {
            const { description: traitDescription, ...rest } = member;
            return { traitDescription, ...rest }
          });
          newAssessmentMembers.forEach((ele : any) => ele.selected = this.geAssessmentsList.find((e: any) => e.traitId == ele.traitId) ? this.geAssessmentsList.find((e: any) => e.traitId == ele.traitId).selected : false);
          this.geAssessmentsList = [...newAssessmentMembers];
          this.geAssessmentsList = this.geAssessmentsList.filter((value, index, self) =>
            index === self.findIndex((t) => (
              t.traitId === value.traitId
            ))
          );                        
      }
    });
  }

  removeGeAssessments() {
    this.geAssessmentsList = this.geAssessmentsList.filter( ele => !this.selectedAssessments.find((item : any) => item.traitId == ele.traitId));
    this.selectedAssessments.splice(0);
  }

  showPStageDialog() {
    this.showPStageModal = true;
    this.pStageDataCopy = JSON.parse(JSON.stringify(this.pStageData));
  }

  savePStageData(saveStage: boolean) {
    if (!saveStage){
      this.showPStageModal = false;
      return;
    }
    if (this.pStageData.find(ele => (ele.type != "" && (ele.name.length === 0 || ele.year.length == 0)) || (ele.type == "" && (ele.name.length === 0 && ele.year.length == 0)))) {
      this.confirmationService.confirm({
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        message: `There are ${this.pStageData.length - this.validPstageData} row(s) with incomplete data found. Do you want to save remaining ${this.validPstageData} row(s).`,      
        accept: () => {
          this.pStageData = this.pStageData.filter(ele => ele.type !== "" && (ele.name.length > 0 && ele.year.length > 0));
          this.pStagesList = JSON.parse(JSON.stringify(this.pStageData));
          this.pStagesList.forEach((ele : any) => {ele.name = ele.name.join(","); ele.year =ele.year.join(",");});
          this.showPStageModal = false;
 
        }
      });
    } else {
      this.showPStageModal = false;
      this.pStagesList = JSON.parse(JSON.stringify(this.pStageData))
      this.pStagesList.forEach((ele : any) => {ele.name = ele.name.join(","); ele.year =ele.year.join(",");});
    }
  }


  addPStageRow() {
    const object : any = {'id' : this.pStageCounter,'type':"",'name':[],'year': [], crop : (this.selectedCrop ? this.selectedCrop.Name : undefined),selected : false};
    this.pStageCounter++;
    this.pStageData = [object,...this.pStageData];
  }

  deletePStageRow() {
    const removeRows = this.pStageGridApi.getSelectedRows();
    this.pStageData = this.pStageData.filter(ele => removeRows.indexOf(ele) == -1);
    this.pStageCounter - removeRows.length;
  } 

  onPStageGridReady($event: GridReadyEvent<any>) {
    this.pStageGridApi = $event.api;
  }

  removePStage() {
    this.pStagesList = this.pStagesList.filter( ele => !this.selectedPStages.find((item : any) => item.id == ele.id));
    this.pStageData = this.pStageData.filter( ele => !this.selectedPStages.find((item : any) => item.id == ele.id));
    this.selectedPStages.splice(0);
  }
}
