<p-panel [toggleable]="true" [collapsed]="collapseSearch" header="Search Criteria" >
    <ng-template pTemplate="icons">
                <p-button id="buttonStyle" data-toggle="tooltip" data-placement="top"  title="Select crop and enter CVN or GE Id(s) to retrieve Data" [disabled]="!(idListText.length > 0 && this.selectedCrop)" (onClick)="retrieveData()"> Retrieve</p-button>
                <p-button id="buttonStyle" data-toggle="tooltip" data-placement="top" title="Reset Changes"  (onClick)="resetPreferences()"> Reset</p-button>
    </ng-template>
    <div style="display: flex;justify-content: flex-start;margin-top: 15px;">       
        <div style="margin-left: 10px;">
            <label style="font-weight: bold;font-size: large;vertical-align: middle;"> Search By:</label>
            <p-radioButton  value="CVN" [(ngModel)]="searchBy" label="CVN" (onClick)="setSearch($event)"/>
            <p-radioButton value="GE ID"   label="GE ID" [style]="{'margin-left': '5px'}"[(ngModel)]="searchBy"  (onClick)="setSearch($event)"/>
            <label  style="font-weight: bold; display: block; margin-bottom: 12px;margin-top: 5px;">Total Rows : {{idList.length}}</label>
            <span>
            <textarea style="max-height: 244px;" [rows]="10" [cols]="20" [autoResize]="true" [(ngModel)]="idListText" pInputTextarea></textarea>
            </span>
        </div>
        <p-floatLabel style="margin-left: 20px;">
            <p-dropdown [options]="crops" [(ngModel)]="selectedCrop" [filter]="true" optionLabel="Name" placeholder="Select" />
            <label  style="font-size: large;" >Crop </label>
        </p-floatLabel>
    </div>
</p-panel>
<p-panel [toggleable]="true" [collapsed]="collapseResults" header="Results" [style]="{'margin-top': '5px', 'height':'auto'}">
    <ng-template pTemplate="icons">
                <p-button id="buttonStyle" data-toggle="tooltip" data-placement="top"  title="Export Excel" [disabled]="rowData.length === 0" (onClick)="exportExcel()"> Export</p-button>
                <p-button id="buttonStyle" data-toggle="tooltip" data-placement="top"  title="Save Grid Preferences" [disabled]="rowData.length === 0" (onClick)="saveGridPreferences()"> Save Grid Preferences</p-button>
                <p-button id="buttonStyle" data-toggle="tooltip" data-placement="top" title="Code"  (onClick)="resetPreferences()"> Code</p-button>
                <p-button id="buttonStyle" data-toggle="tooltip" data-placement="top" title="Cancel Changes"  (onClick)="resetPreferences()"> Cancel</p-button>
    </ng-template>
    <ag-grid-angular *ngIf="rowData.length > 0" id="agGrid"
        style="height: 70vh; width: 100%;" class="ag-theme-balham"  [columnDefs]="columnDefs" [defaultColDef]="defaultColDef"
        [allowContextMenuWithControlKey]="true" [getContextMenuItems]="getContextMenuItems" [suppressAsyncEvents]="true" 
        [singleClickEdit]="false"
        [animateRows]="true" [rowData]="rowData" [rowDragManaged]="true" [rowDragEntireRow]="true"
        [gridOptions]="gridOptions" [suppressRowClickSelection] = "true"  [statusBar]="statusBar" (gridReady)="onGridReady($event)"                      
        [rowSelection]="'multiple'" [suppressRowClickSelection]="true" [animateRows]="true"></ag-grid-angular>
</p-panel>
<p-confirmDialog [style]="{'width' : '450px', 'height' : '230px'}" appendTo="body">
</p-confirmDialog> 
  
    
  