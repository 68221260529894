import { PStageInfo } from "./pStageInfo";
import { UserPreferredGeTrait } from "./userPreferredGeTrait";

export class PreCodePreferences {
    id : string;
    type : string;
    crop : string;
    level : string;
    include : string;
    stage :string;
    years: string;
    favouritePrograms: string;
    geLineage : boolean;
    seedInventory : boolean;
    popRequest : boolean;
    experiments : boolean;
    appUserId : string;
    gensToSummarize : any;
    userPreferredGeTraits : UserPreferredGeTrait [];
    userPreferredPStages : PStageInfo[];
}