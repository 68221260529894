import { HttpParams } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AgGridAngular } from 'ag-grid-angular';
import { ColDef, GetContextMenuItemsParams, GridOptions, GridPreDestroyedEvent, GridReadyEvent, IMultiFilterParams, IRichCellEditorParams, MenuItemDef, StatusPanelDef } from 'ag-grid-community';
import { ConfirmationService } from 'primeng/api';
import { RadioButtonClickEvent } from 'primeng/radiobutton';
import { Crop } from 'src/app/models/crop';
import { GridPreference } from 'src/app/models/gridPreference';
import { ApiService } from 'src/app/services/api.service';
import { CommonService } from 'src/app/services/common.service';
import { CustomMessageService } from 'src/app/services/custom.message.service';
import { StaticFields } from 'src/app/shared/staticFields';

@Component({
  selector: 'app-code',
  templateUrl: './code.component.html',
  styleUrl: './code.component.scss'
})
export class CodeComponent implements OnInit {

  selectedCrop : any;
  disableCrop : boolean = true;
  collapseSearch: boolean = false;
  collapseResults: boolean = true;
  assessmentMembersList: any[]= [];
  assessmentMembers: any[] = [];
  searchType: string = "Get All Assessments";
  searchBy: string = "GE ID";
  selectType: string = "Show all Assessments";
  showSelected : boolean = false
  selectedMembers: any;
  enableSearch : boolean = false;
  rowData : any[] =[];
  idList: string[] = [];
  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;
  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    minWidth : 50, 
    resizable: true,
  };
  public columnDefs: ColDef[] = [
    { field: 'crop', headerName : 'Crop', filter: 'agTextColumnFilter', width : 165,  headerTooltip : 'Crop', },
    { field: 'cvn', headerName : 'CVN', filter: 'agTextColumnFilter',width : 165,headerTooltip : 'CVN' },
    { field: 'stage', headerName : 'PStage', filter: 'agTextColumnFilter', width : 16, headerTooltip: 'PStage' },
    { field: 'tag', headerName : 'Tag', filter: 'agTextColumnFilter', width : 165, headerTooltip : 'Tag'},
    { field: 'hybridRole', headerName : 'Hybrid Parent Role', filter: 'agTextColumnFilter', width : 165, headerTooltip : 'Hybrid Parent Role' },
    { field: 'notSentToTI', headerName : 'Not Sent To TI', headerTooltip : 'Not Sent To TI',filter: 'agTextColumnFilter', editable: true ,cellRenderer: 'agCheckboxCellRenderer', width : 165},
    { field: 'generation', headerName : 'Generation', headerTooltip: 'Generation' ,filter: 'agTextColumnFilter', width : 165 },
    { field: 'siid', headerName : 'SIID', headerTooltip: 'SIID', filter: 'agTextColumnFilter', width : 165}
  ];
  public statusBar: {
    statusPanels: StatusPanelDef[];
  } = {
    statusPanels: [
      { statusPanel: 'agTotalRowCountComponent', align: 'left' },
      { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left',  },
      { statusPanel: 'agFilteredRowCountComponent', align : 'left' },
      { statusPanel: 'agSelectedRowCountComponent', align : 'left' },
    ],
  };
  gridOptions : GridOptions = {
    context: {
      componentParent: this,
    },
    defaultColDef: {
      width: 165,
      minWidth: 50,
      maxWidth : 500,
      filter: 'agMultiColumnFilter',
      filterParams: {
        filters: [
          {
            filter: "agSetColumnFilter"
          },
        ]
      } as IMultiFilterParams
    },
    tooltipShowDelay: 100,
    singleClickEdit: true,
    sideBar: { toolPanels: ['columns', 'filters'] },
    onGridPreDestroyed: this.onGridPreDestroyed
  }
  gridApi: any;
  selectedType: string;
  userGridPreference: GridPreference = new GridPreference();
  navigatedExternal: boolean = false;
  rowDataCopy: any[];


  constructor(private apiService : ApiService, public commonService : CommonService,  public customMessageService: CustomMessageService, 
    private confirmationService : ConfirmationService, private router : ActivatedRoute) {
    this.selectedType = 'Show all Assessments';
  }
  get idListText(): string {
    return this.idList.join("\r\n");
  }

  set idListText(value) {
    this.idList = value.split(/\r?\n/);
    if (this.idList.length < 2)
      this.idList = value.split(",");
    this.idList = this.idList.filter((v, i, a) => a.indexOf(v) === i);
    this.idList = this.idList.map(s => s.trim()).filter(s => s);
  }

  get crops(): Crop [] {
    return StaticFields?.crops && StaticFields?.crops?.length > 0 ? StaticFields?.crops?.filter(ele => ele.isActive) : [];
  }

  ngOnInit(): void {
    if (StaticFields.appUserId)
      this.setScreenData();  
    else {
      const interval = setInterval(() => {
        if (StaticFields.appUserId) {
          this.setScreenData();
          clearInterval(interval);
        }
      }, 2000);
    }
    
  }

  setScreenData() {
    this.router.queryParamMap.subscribe((p : any) => {
      if (p['params'] && p['params']['selectedGes']) {
        this.navigatedExternal = true;
        this.selectedCrop = this.crops.find(ele => ele.Name == p['params']['crop']);
        const geIds = p['params']['selectedGes'];
        if (geIds) {
          this.idList = geIds;
          this.retrieveData();
        }   
      }
    })
    if (StaticFields.codingPageData && !this.navigatedExternal) {
      this.selectedCrop = this.crops.find(ele => ele.Name == StaticFields.codingPageData.crop.name);
      this.searchBy = StaticFields.codingPageData['cvn'].length > 0 ? "CVN" : "GE ID"
      this.idList = StaticFields.codingPageData['cvn'].length > 0 ? StaticFields.codingPageData['cvn'] : StaticFields.codingPageData['geIds'];
      this.columnDefs = StaticFields.codingPageData.columnDefs;
      this.rowData = StaticFields.codingPageData.data;
      this.collapseSearch = true;
      this.collapseResults = false; 

    }
    else if (StaticFields.precodePreference && StaticFields.precodePreference.appUserId && !this.navigatedExternal) {
      this.selectedCrop = this.crops.find(ele => ele.Name == StaticFields.precodePreference.crop);      
    }
  }
  
  getUserGridPreferences(gridName: string, isSessionSelected: false, showLoader: boolean) {
    // let params = new HttpParams();
    // params = params.append('gridName', gridName);
    // params = params.append('appUserId', StaticFields.appUserId);
    // params = params.append('hasSessionData', isSessionSelected);
    this.apiService.getOData(`UserGridPreferences?$filter=AppUserId eq ${StaticFields.appUserId} and GridName eq '${gridName}'`, showLoader).subscribe((res: any) => {
      if (res.httpStatusCode == 200 && res.data) {
         this.userGridPreference = res.data ? res.data : new GridPreference();
      }
    });
  }
  
  setSearch($event: RadioButtonClickEvent) {
   if($event) {
    this.idList = []
   }
  }

  resetPreferences() {
    this.confirmationService.confirm({
      header: 'Reset',
      icon: 'pi pi-exclamation-triangle',
      message: `Do you want to reset the search criteria ?`,      
      accept: () => {
        this.selectedCrop = this.crops.find(ele => ele.Name === StaticFields.precodePreference.crop);
        this.idList = [];
        this.rowData.splice(0);
        if(this.columnDefs.length > 8)
          this.columnDefs.splice(8);
        this.collapseSearch = false;
        this.collapseResults = true;
        StaticFields.codingPageData = null;
      }
    });
    
  }
  fill(params: any, fillType: string) {
    if (fillType === "down" || fillType === "up") {
      const children = params.node.parent.childrenAfterGroup;
      let idx = children.findIndex((c: any) => c.id === params.node.id);
      children.forEach((rowNode: any) => {
        if (fillType === "up" ? idx > rowNode.childIndex : idx < rowNode.childIndex) {          
            rowNode.data[params.column.colId] = params.value;
          }
      });
    } else if (fillType === "selected") {
      params.api.getSelectedRows().forEach((rowNode: any) => {       
          rowNode.data[params.column.colId] = params.value;
        
      });
    }
    params.api.refreshCells(params);
  }

  getContextMenuItems(  params: GetContextMenuItemsParams,
  ): (string | MenuItemDef)[] {
    const result: (string | MenuItemDef)[] = [
      {
        name: 'Fill Up',
        disabled: !(params.value && params.node.rowIndex > 0),
        action: function () { params.context.componentParent.fill(params, "up"); },
      },
      {
        name: 'Fill Down',
        disabled: !(params.value && params.node.rowIndex < params.context.componentParent.rowData.length),
        action: function () { params.context.componentParent.fill(params, "down"); }
      },
      {
        name: 'Fill Selected',
        disabled: !(params.value && params.api.getSelectedRows().length > 1),
        action: function () { params.context.componentParent.fill(params, "selected"); }
      },
      'copy',
      'copyWithHeaders',
      'separator',
      'export'
    ]
    return result;
  }

  retrieveData() {
    let postData : any = {};
    postData['crop'] = this.selectedCrop.Name;
    if(this.searchBy == "CVN") {
      postData['geIds'] = [];
      postData['cvn'] = this.idList;      
    } else {
      postData['geIds'] = this.idList.map(id => Number(id));
      postData['cvn'] = [];
    }
    this.apiService.postData("SearchCodingData", postData, false,true).subscribe({
      next: (response: any) => {
        this.getUserGridPreferences(this.selectedCrop.Name + ":" + 'codePage',false, false);
        if(response.httpStatusCode == 200) {
          let data = response.data[0];
          if(data && data?.result?.length > 0) {
            setTimeout(() => {
              const validValues = data.validValues;
              validValues.forEach((ele : any) => {
                if(ele.vvsType == "RANG")   {     
                  const members = ele.validValue.members[0];      
                  this.columnDefs.push( { field: ele.traitName.toLowerCase(), headerName : ele.traitName.toLowerCase(),headerTooltip:`Enter a value between ${members['lowerValue']} to ${members['upperValue']}`, editable: true, filter: 'agTextColumnFilter', width : 165,
                  cellEditor: 'agNumberCellEditor',
                  cellEditorParams: {
                    values : ele.validValue.members               
                  },  
                  cellStyle: params => {
                      if((params.value !== "" &&  (Number(params.value) < members['lowerValue'] || Number(params.value) >  members['upperValue']))) {
                        return { backgroundColor: 'orange'}
                      } else {
                        return null;
                      }
                    },
                    valueParser: params => Number(params.newValue)
                  });
                } else {
                  const members : any[] = ele.validValue.members.map((e: any) => e.rangeName);      
                  this.columnDefs.push( { field: ele.traitName.toLowerCase(), headerName : ele.traitName.toLowerCase(), headerTooltip:ele.traitName , editable: true, filter: 'agTextColumnFilter', width : 165,
                    cellEditor: 'agRichSelectCellEditor',
                    cellEditorParams: {
                      values : members,
                      searchType: 'matchAny',
                      filterList: true,
                      highlightMatch: true
                    } as IRichCellEditorParams,
                  },)
                }                
              });
              const codingPageData: any = new Object();
              const ids : any = this.idList;
              if(this.searchBy == "CVN") {
                data.result.sort(function (a : any, b : any) {
                  return ids.indexOf(a.cvn) - ids.indexOf(b.cvn);
                });
              } else {
                let searchIds : any = [];
                ids.forEach((ele : any) => searchIds.push(Number(ele)));              
                data.result.sort(function (a : any, b : any) {
                  return searchIds.indexOf(Number(a.geId)) - searchIds.indexOf(Number(b.geId));
                });
              }
              codingPageData.data = this.rowData = this.rowDataCopy = [...data.result];
              codingPageData.crop = this.selectedCrop;
              codingPageData['userGridPreference'] = this.userGridPreference;
              codingPageData.columnDefs = this.columnDefs;
              if(this.searchBy == "CVN") {
                codingPageData['geIds'] = [];
                codingPageData['cvn'] = this.idList;      
              } else {
                codingPageData['cvn'] = [];
                codingPageData['geIds'] = this.idList;    
              }
              StaticFields.codingPageData = codingPageData;              
              this.collapseSearch = true;
              this.collapseResults = false;              
            },500)
          }
         else {
          this.customMessageService.showMessage({severity: "warn",summary: "No Data Found", detail: "There was no data found for selected combination" })
         }                 
        }
      }
    });
  }

  exportExcel() {
    this.commonService.downloadExcelFile(this.agGrid, false, false, true);
  }

  onGridPreDestroyed($event: GridPreDestroyedEvent<any,any>) {
    if (!$event.context.showGrouped && StaticFields.codingPageData)
      StaticFields.codingPageData.colState = $event.api.getColumnState();
  }

  saveGridPreferences() {
    const cols = JSON.stringify(this.gridApi.getColumnState());
    if (!this.userGridPreference.appUserId) {
      this.userGridPreference.appUserId = StaticFields.appUserId;
      this.userGridPreference.gridName= this.selectedCrop.Name + ":" + "codePage";
    }
    StaticFields.codingPageData.userGridPreference =  this.userGridPreference.preference = cols;
    this.apiService.putData('SubmitUserGridPreference', this.userGridPreference).subscribe();   
  }


  onGridReady($event: GridReadyEvent<any>) {
    this.gridApi = $event.api;
    let preference = null;
    let savedCols = null;
    if(StaticFields.codingPageData && StaticFields.codingPageData?.colState){
      savedCols = StaticFields.codingPageData?.colState;
      this.gridApi.applyColumnState({ state: savedCols, applyOrder: true });
    }
    else if (this.userGridPreference?.preference) {
       savedCols = JSON.parse(this.userGridPreference.preference);
       this.gridApi.applyColumnState({ state: savedCols, applyOrder: true });
    }
  }

}
