import { Component, OnInit, Renderer2, RendererFactory2, ViewChild, ViewContainerRef } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import { CellEditingStartedEvent, CellEditingStoppedEvent, ColDef, GridOptions, GridReadyEvent, StatusPanelDef } from 'ag-grid-community';
import { ConfirmationService } from 'primeng/api';
import { Crop } from 'src/app/models/crop';
import { ApiService } from 'src/app/services/api.service';
import { CropUpdateService } from 'src/app/services/cropUpdate.service';
import { ManageCropModalHeaderComponent } from 'src/app/shared/cropManageHeader';
import { ReportModalHeaderComponent } from 'src/app/shared/reportModalHeader';
import { StaticFields } from 'src/app/shared/staticFields';

@Component({
  selector: 'app-managecrop',
  templateUrl: './managecrop.component.html',
  styleUrl: './managecrop.component.scss'
})
export class ManagecropComponent implements OnInit {
  rowData : any[] =[];
  idList: string[] = [];
  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;
  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    resizable: true,
  };
  public columnDefs: ColDef[] = [
    { field: 'Name', headerName: 'Crop', minWidth: 50},
    { field: 'Description', headerName: 'Description',minWidth: 50},
    { field: 'isActive', headerName: 'Active', cellEditor: 'agCheckboxCellEditor', editable: true,minWidth: 30}
  ];
  public statusBar: {
    statusPanels: StatusPanelDef[];
  } = {
    statusPanels: [
      { statusPanel: 'agTotalRowCountComponent', align: 'left' },
      { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left',  },
      { statusPanel: 'agFilteredRowCountComponent', align : 'left' },
      { statusPanel: 'agSelectedRowCountComponent', align : 'left' },
      { statusPanel: 'agAggregationComponent' },
    ],
  };
  gridOptions : GridOptions = {
    context: {
      componentParent: this,
    },
    singleClickEdit: true,
    tooltipShowDelay: 100,
    
    sideBar: { toolPanels: ['columns', 'filters'] },
    
    // onGridPreDestroyed: this.onGridPreDestroyed
  }
  gridApi: any;
  renderer: Renderer2;

  get allowReset() : boolean {
    const managedRadCrops = this.rowData.map(ele => {return {Name :ele.Name,Description :  ele.Description, isActive: ele.isActive}}).sort((a: any , b: any) => a.Name.localeCompare(b.Name));
    const allRadCrops = StaticFields?.crops?.map(ele => {return {Name :ele.Name,Description :  ele.Description, isActive: ele.isActive}}).sort((a: any , b: any) => a.Name.localeCompare(b.Name));
    return JSON.stringify(managedRadCrops) == JSON.stringify(allRadCrops);

  }
  get crops(): Crop [] {
    return StaticFields?.crops && StaticFields?.crops?.length > 0 ? StaticFields?.crops : [];
  }

  constructor(private apiService : ApiService,  private confirmationService : ConfirmationService, private cropUpdateService : CropUpdateService,
    private readonly rendererFactory: RendererFactory2,
    private viewContainerRef: ViewContainerRef) {
    this.renderer = rendererFactory.createRenderer(null,null);
  }
  
  ngOnInit(): void {
    if(StaticFields?.crops && StaticFields?.crops?.length > 0 ) {
    //  StaticFields?.crops?.forEach(ele => ele.isActive = false);
     this.rowData = JSON.parse(JSON.stringify(StaticFields?.crops));
     this.setScreenData();
    }
  }

  setScreenData() {
    setTimeout(() => {
      const componentRef = this.viewContainerRef.createComponent(ManageCropModalHeaderComponent);
      componentRef.instance.incomingData = Object.create(this);
      let titleSpan = document.getElementsByClassName('p-dialog-header-icons')[0];
      this.renderer.insertBefore(titleSpan, componentRef.location.nativeElement,titleSpan.childNodes[0]);
    },300);
  }

  onCellEditingStarted(event: CellEditingStartedEvent) {
    if(event.data.Name === StaticFields.precodePreference.crop && event.value == true) {
      this.confirmationService.confirm({
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        message: 'Are you sure you want to make the crop inactive, it is saved as default preference ?',
        accept: () => {           
        },
        reject: () => {
          event.data.isActive = true;
          this.gridApi.redrawRows()
        }
        
      })
    }
  }

  onCellEditingStopped(event: CellEditingStoppedEvent) {
  }

  onGridReady($event: GridReadyEvent<any>) {
    this.gridApi = $event.api;
  }
  
  submitData() {
    this.apiService.putData( "SubmitCropPreference", this.rowData).subscribe((result : any) => {
      if (result.httpStatusCode == 200) { 
        this.rowData.forEach((ele : any) => {
          const index = StaticFields.crops.findIndex(e => e.Name == ele.Name);
          if(index > -1){
            StaticFields.crops[index].isActive = ele.isActive;
            if(StaticFields.precodePreference.crop == ele.Name && !ele.isActive)
              this.cropUpdateService.resetDataCommunicate("true");
          }
        })
      }
    });
  }

  reset() {
    this.confirmationService.confirm({
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      message: 'Are you sure you want to reset? Any unsaved changes will be lost',
      accept: () => {
        this.rowData = JSON.parse(JSON.stringify(StaticFields?.crops));
      }
      
    })
  }
}
