import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus, RedirectRequest } from '@azure/msal-browser';
import { filter, Subject, takeUntil } from 'rxjs';
import { ApiService } from './services/api.service';
import { StaticFields } from './shared/staticFields';
import * as FileSaver from 'file-saver';
import { Settings } from './models/settings';
import { SettingService } from './services/setting.service';
import { environment } from 'src/environments/environment';
import { DOCUMENT } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Router } from '@angular/router';
import { NgxCaptureService } from 'ngx-capture';
import { CustomMessageService } from './services/custom.message.service';
import { ConfirmationService } from 'primeng/api';
import { CommonreportmodalComponent } from './components/commonreportmodal/commonreportmodal.component';
import { ManagecropComponent } from './components/managecrop/managecrop.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  public versionNumber = environment.appVersion;
  public environment = "";
  title = 'PRECODEMANAGER_UI';
  imageSrc: string = "";
  isIframe: boolean = false;
  isNavVisible: boolean = false;
  isPCMAdmin: boolean = false;
  private readonly _destroying$ = new Subject<void>();
  loginDisplay: boolean = false;
  errors: any[] = JSON.parse(localStorage.getItem("errors") || "[]");
  translationForGrid: any[] = [];
  showMapModal: boolean = false;
  selectedMapHeaders : any[] = [];
  ref: DynamicDialogRef;

  constructor(
    @Inject(SettingService) private config: Settings,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalService: MsalService,
    private apiService : ApiService,
    public customMessageService: CustomMessageService,
    private msalBroadcastService: MsalBroadcastService,
    public dialogService : DialogService,
    private router: Router, 
    private confirmationService : ConfirmationService,
    private captureService : NgxCaptureService,
    @Inject(DOCUMENT) private document: Document
  ) {      
    if (this.config.environment.toUpperCase() === 'LOCAL' || this.config.environment.toUpperCase() === 'DEV' || this.config.environment.toUpperCase() === 'QA' || this.config.environment.toUpperCase() === 'TR') {
      this.environment = "(" + this.config.environment + ")";
    }
    this.customMessageService.errorMsgs$.subscribe(result => {
      if (result.severity === 'error') {
        setTimeout(()=> {
          this.captureService.getImage(document.body, true).subscribe((img: any)=>{
            result['img'] = img;
            let collection = [];
            let submitData : any = {};
            // submitData['guid'] = this.getGUID();
            submitData['errorDateTime'] = new Date(result.dateTime).toUTCString();
            submitData['errorMessage'] = result.detail == undefined ? result.summary : result.detail;
            submitData['screenShot'] = result.img;
            submitData['actionById'] = StaticFields.appUserId;
            collection.push(submitData);
            this.apiService.putData("SubmitPCMErrorScreenShots", collection, false, false).subscribe();
            this.appendError(result);
          })
        },1000);
      } else
        this.appendError(result);
    });
   
  }

  get allowSave(): boolean {
    if (this.translationForGrid.filter(p => p.headerName != undefined).length > 0) {
      let data : any = {};
      this.translationForGrid.forEach((item : any) => {
        data[item.headerName] = item.data;
      });
      return StaticFields.homeScreenData.mappingModel !== JSON.stringify(data);
    }
    return false;
  }


  ngOnInit(): void {
    this.isIframe = window !== window.parent && !window.opener;
    this.msalService.instance.enableAccountStorageEvents();
    this.msalBroadcastService.msalSubject$
    .pipe(
      filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED),
    )
    .subscribe(async (result: EventMessage) => {
      if (this.msalService.instance.getAllAccounts().length === 0) {
        // await this.msalService.instance.handleRedirectPromise();
        await this.msalService.instance.loginRedirect();
        window.location.pathname = "/";
      } else {
        this.setLoginDisplay();
      }
    });
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None)
      )
      .subscribe(() => {
        this.checkAndSetActiveAccount();
      });
     
    if (this.msalService.instance.getActiveAccount()) {
      this.getRoles();    
      this.setOnLoad();
    } else {
      this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
        takeUntil(this._destroying$)
      )
      .subscribe((result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        this.msalService.instance.setActiveAccount(payload.account);
        if (this.msalService.instance.getAllAccounts().length === 0) {
          this.isNavVisible = false;
        } else {
            this.getRoles();            
        }
        if (this.isNavVisible) { 
          this.setOnLoad();        
        }
      });
    }       
  }

  ngAfterViewInit(): void {
    const elements = document.getElementsByClassName("footer-container");
    while(elements.length > 0){
        elements[0]?.parentNode.removeChild(elements[0]);
    }
  }

  setLoginDisplay() {
    this.loginDisplay = this.msalService.instance.getAllAccounts().length > 0;
  }

  private setOnLoad() {
    if (StaticFields.userInfo === undefined || StaticFields.userInfo === null) {
        this.apiService.getUserInfo().subscribe((result: any) => {
          StaticFields.userInfo = result;
          this.setInitialData();
        });  
    } else {
      this.setInitialData();
    }
  }

  setInitialData() {
    if (StaticFields.crops === undefined || StaticFields.crops === null) {
      this.apiService.getRADData('Crops?&select=Id,Name,Description').subscribe((result : any) => {
         StaticFields.crops = result.value.sort((a: any , b: any) => a.Name.localeCompare(b.Name));
         this.apiService.getOData('CropPreferences').subscribe((result : any) => {
          if(result && result.value.length > 0) {
            result.value.forEach((ele : any) => {
              const index = StaticFields.crops.findIndex(e => e.Name == ele.name);
              if(index > -1)
                StaticFields.crops[index].isActive = ele.isActive;
            })
          } else {
            StaticFields.crops.forEach(ele => ele.isActive = true);
          }
         });

        //  this.apiService.getData("GetCropPreferences").subscribe((result : any) => {
        //   if(result && result?.data.length > 0) {
        //     result.data.forEach((ele : any) => {
        //       const index = StaticFields.crops.findIndex(e => e.Name == ele.name);
        //       if(index > -1)
        //         StaticFields.crops[index].isActive = ele.isActive;
        //     })
        //   } else {
        //     StaticFields.crops.forEach(ele => ele.isActive = true);
        //   }
        //  });
         if (StaticFields.homeScreenData === undefined || StaticFields.homeScreenData === null) {
          let params = new HttpParams();
          params = params.append('userName', StaticFields.userInfo.onPremisesSamAccountName);
          this.apiService.getData('GetHomePageData', params).subscribe((result : any) => {
            if (result.httpStatusCode == 200) {
              StaticFields.homeScreenData = result.data ? result.data : {              
                "actionById" : StaticFields.appUserId,             
                "box1" : "",
                "box2" : "",
                "box3" : "",
                "box4" : "",
                "mappingModel" : ""
              };
              let params = new HttpParams();
              params = params.append('type', 'PreCode');
              params = params.append('appUserId', result.appUserId);
              this.apiService.getData('GetPreferences', params, false).subscribe((res: any) => {
                if (res.httpStatusCode == 200) {
                  StaticFields.precodePreference = res.data[0];
                  if (StaticFields.precodePreference) {
                    const name = StaticFields.precodePreference.gensToSummarize === -100 ? "All" : StaticFields.precodePreference.gensToSummarize.toString();
                    StaticFields.precodePreference.gensToSummarize = {name: name, val: StaticFields.precodePreference.gensToSummarize};
                  }
                }
                StaticFields.appUserId = result.appUserId; 
              });          
            }
          });     
        }
      });    
      this.apiService.getOData('MasterData').subscribe((result : any) => {
        StaticFields.masterData = result.value;
      });
      this.apiService.getOData('MetaData').subscribe((result : any) => {
        StaticFields.metaData = result.value;
      });
    }
  }
  
  appendError(result: any) {
    let errors = JSON.parse(localStorage.getItem("errors") || "[]");
    if (errors.length === 10) {
      errors.pop();
      errors.unshift(result);
    } else {
      errors.unshift(result);
    }
    this.errors = errors;
    localStorage.setItem("errors", JSON.stringify(errors));
  }
  
  clearErrors() {
    this.errors = [];
    localStorage.setItem("errors", JSON.stringify(this.errors));
  }

  showMappingModal() {
    if (StaticFields.homeScreenData && StaticFields.homeScreenData.mappingModel)
      this.fillModelData(StaticFields.homeScreenData.mappingModel);
    this.showMapModal = true;
  }

  showCropManageModal() {
    this.ref = this.dialogService.open(ManagecropComponent, {
      
      header:'Manage Crops',
      width: '35%',
      height: '100%',
      styleClass: 'reportModal',
      appendTo: 'body',
      modal: true,
      closable : true,
    })
}

  showReportModal(type: string) {
      this.ref = this.dialogService.open(CommonreportmodalComponent, {
        data : {
          url: type == 'experiment' ? 'GetExperimentDataByGEs' : type ==  'pstage' ? 'GetPStageDataByGEs' : 'GetLineGraph',        
          crop : StaticFields.precodePreference.crop,
          report : false
        },
        header: type == 'experiment' ? 'Experiment Detail Report' : type ==  'pstage' ?  'PStage Detail Report' : 'Linegraph Detail  Report',
        width: '95%',
        height: '70%',
        styleClass: 'reportModal',
        appendTo: 'body',
        modal: true,
        closable : true,
      })
  }

  onAddNewRow(){
    this.translationForGrid.unshift({});
  }

  onRemoveRow() {
    this.translationForGrid = this.translationForGrid.filter(ele => {return  !this.selectedMapHeaders.find(val =>  {return val.headerName == ele.headerName})});
    this.selectedMapHeaders.splice(0);
  }

  fillModelData(data : any) {
    this.translationForGrid.splice(0);
    if (data) {
      const translationData = JSON.parse(data);
      Object.keys(translationData).forEach(ele => {
        this.translationForGrid.push({'headerName': ele, 'data': translationData[ele] });
      });
    }
  }

  SubmitHomePageMappingModel() {
    let data : any = {};
    this.translationForGrid.forEach((item : any) => {
      data[item.headerName] = item.data;
    })
    StaticFields.homeScreenData.mappingModel = JSON.stringify(data);
    this.apiService.putData('SubmitHomePageData', StaticFields.homeScreenData).subscribe((result : any)  => {
      if (result && result.httpStatusCode == 200) {
        StaticFields.homeScreenData = result.data;
        this.fillModelData(StaticFields.homeScreenData.mappingModel);
        this.showMapModal = false;
      }      
    });
  }

  // downloadImage(data : any) {
  //   const blob = this.DataURIToBlob(data.img);
  //   FileSaver.saveAs(blob, "screenshot.png");
  // }

  bgColor(rowData : any) : any {
    if(rowData.severity === "success") {
      return "#b7d8b7";
    }else if(rowData.severity === "error") {
      return "#f8b7bd";
    }else if(rowData.severity === "warn") {
      return "#ffe399";
    }else if(rowData.severity === "info") {
      return "#7fbcec";
    }
  }

  color(rowData : any) : any {
    if(rowData.severity === "success") {
      return "#000000";
    }else if(rowData.severity === "error") {
      return "#000000";
    }else if(rowData.severity === "warn") {
      return "#000000";
    }else if(rowData.severity === "info") {
      return "#000000";
    }
  }


  // DataURIToBlob(dataURI: string) {
  //   const splitDataURI = dataURI.split(',');
  //   const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
  //   const mimeString = splitDataURI[0].split(':')[1].split(';')[0]
        
  //   const ia = new Uint8Array(byteString.length)
  //   for (let i = 0; i < byteString.length; i++)
  //     ia[i] = byteString.charCodeAt(i)
      
  //   return new Blob([ia], { type: mimeString })
  // }

  public login() {
    if (this.msalGuardConfig.authRequest) {
      this.msalService.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest).subscribe({
        next: (res) => {
        },
        error: (error) => {
          console.log(error);
        },
      });
    } else {
      this.msalService.loginRedirect();
    }
  }

  checkAndSetActiveAccount() {
    let activeAccount = this.msalService.instance.getActiveAccount();
    if (!activeAccount && this.msalService.instance.getAllAccounts().length > 0) {
      let accounts = this.msalService.instance.getAllAccounts();
      this.msalService.instance.setActiveAccount(accounts[0]);
      
    } 
    if (activeAccount) {      
      const url = this.document.location.toString();
      const pageName = url.substring(url.lastIndexOf("/"), url.length);
      this.getRoles();      
    } else {

    }
  }

  public getRoles() {
    let allAccounts = this.msalService.instance.getAllAccounts();
    if (allAccounts.length > 0) {
      let account = allAccounts[0];
      let roles = account.idTokenClaims!.roles;
      const firstLoginFailed = sessionStorage.getItem("firstLoginFailed");
      if (!roles && !firstLoginFailed) {
        sessionStorage.setItem("firstLoginFailed", "yes");
        this.msalService.logout();
      } else {
        if (roles!.length > 0 ) {         
          this.isPCMAdmin = StaticFields.userClaim.isPCMAdmin = roles!.find(ele => ele == "PCM.Admin") ? true : false;
          StaticFields.userClaim.isPCMUser = roles!.find(ele => ele == "PCM.User") ? true : false;
          StaticFields.userClaim.isPCMView= roles!.find(ele => ele == "PCM.View") ? true : false;
          this.isNavVisible = true;  
        } else {
          console.log("No Roles found");
          this.router.navigate(['403']);
        }
      }
    } else {
      this.router.navigate(['403']);
    }
  }

  downloadImage(data : any) {
    const blob = this.DataURIToBlob(data.img);
    FileSaver.saveAs(blob, "screenshot.png");
  }

  DataURIToBlob(dataURI: string) {
    const splitDataURI = dataURI.split(',');
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0]
        
    const ia = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i)
      
    return new Blob([ia], { type: mimeString })
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
